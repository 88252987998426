<template>
  <div class="page">
    <el-button type="text" size="medium" @click="getRefundList()">退款</el-button>
    <!-- 编辑弹框 -->
    <el-dialog title="订单退款" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="700px">
      <section v-if="refundList.length > 0">
        <!-- 表单提交 -->
        <el-form v-for="(item, index) in refundList" :key="item.orderId" :model="item" :rules="rules"
          :ref="'form' + index" inline>
          <section class="form-main">
            <el-descriptions title="订单支付详情" :column="2">
              <el-descriptions-item label="订单金额">{{
      item.orderAmount || "-"
    }}</el-descriptions-item>
              <el-descriptions-item label="支付方式">{{
        item.paymentTypeStr || "-"
      }}</el-descriptions-item>
              <el-descriptions-item label="卡券类型">{{
        item.cardTypeStr || "-"
      }}</el-descriptions-item>
              <el-descriptions-item label="卡券名称">{{
        item.cardName || "-"
      }}</el-descriptions-item>
              <el-descriptions-item label="支付时间">{{
        item.paymentTime || "-"
      }}</el-descriptions-item>
              <el-descriptions-item label="已退金额">{{
        item.refundInfo || "-"
      }}</el-descriptions-item>
              <el-descriptions-item label="已收回积分">{{
        item.pointsRecoveryAmount || "-"
      }}</el-descriptions-item>
            </el-descriptions>
            <div class="inputBox">
              <el-form-item v-if="item.canRefundAmount != 0" prop="wxRefundAmount"
                :label="'微信退款(可退款金额：' + item.canRefundAmount + ')'">
                <el-input v-model.trim="item.wxRefundAmount" autocomplete="off" maxlength="8" placeholder="请输入微信退款金额"
                  @input="(v) => (item.wxRefundAmount = v.replace(/[^\d.]/g, ''))
      ">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item v-if="item.canRefundBalanceAmount != 0" prop="bjRefundAmount" :label="'本金退款(可退款金额：' + item.canRefundBalanceAmount + ')'
      " @input="(v) => (aaa = v.replace(/[^\d.]/g, ''))">
                <el-input v-model.trim="item.bjRefundAmount" autocomplete="off" maxlength="8" placeholder="请输入本金退款金额"
                  @input="(v) => (item.bjRefundAmount = v.replace(/[^\d.]/g, ''))
      ">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item v-if="item.canRefundGiveAmount != 0" prop="zjRefundAmount" :label="'赠金退款(可退款金额：' + item.canRefundGiveAmount + ')'
      ">
                <el-input v-model.trim="item.zjRefundAmount" autocomplete="off" maxlength="8" placeholder="请输入赠金退款金额"
                  @input="(v) => (item.zjRefundAmount = v.replace(/[^\d.]/g, ''))
      ">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item v-if="item.canRefundUseNum && item.canRefundUseNum != 0" :label="'退还卡券(可退还次数：' +
      (item.canRefundUseNum ? item.canRefundUseNum : 0) +
      ')'
      " prop="refundMemberCardUseNum">
                <el-input v-model.trim="item.refundMemberCardUseNum" autocomplete="off" maxlength="3" @input="(v) =>
      (item.refundMemberCardUseNum = v.replace(/[^\d]/g, ''))
      " placeholder="请填写退还次数">
                  <template slot="append">次</template>
                </el-input>
              </el-form-item>
              <el-form-item v-if="item.canPointsRecoveryAmount != 0" prop="pointsRefundAmount"
                :label="'收回积分(可收回积分数：' + item.canPointsRecoveryAmount + ')'">
                <el-input type="number" v-model.trim="item.pointsRefundAmount" autocomplete="off" placeholder="请输入收回积分数"
                  @keydown.native="(e) =>
    (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
      e.key
    )
      ? false
      : e.returnValue)
      ">
                </el-input>
              </el-form-item>
            </div>
          </section>
          <section class="form-footer">
            <el-button type="info" plain disabled v-if="item.canRefundAmount == 0 &&
      item.canRefundBalanceAmount == 0 &&
      item.canRefundGiveAmount == 0 &&
      item.canPointsRecoveryAmount == 0 &&
      (!item.canRefundUseNum || item.canRefundUseNum == 0)
      ">已退款</el-button>
            <el-button v-else type="primary" :loading="loading"
              @click="onSubmit('form' + index, index)">确认退款</el-button>
          </section>
        </el-form>
      </section>
      <section v-else>
        <el-empty description="暂无订单信息"></el-empty>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import { getRefundList, getRefundEdit } from "@/api/order/order";
import { rules } from "@/db/rules";
export default {
  props: ["orderId"],
  data() {
    return {
      rules,
      // 弹框
      editShow: false, //弹框开关
      refundList: [], //订单退款详情
      loading: false,
    };
  },
  methods: {


    // 判断是否大于可退款金额
    goCheckRefundMoney(index) {
      let item = this.refundList[index];
      if (item.wxRefundAmount > item.canRefundAmount) {
        return 1;
      } else if (item.bjRefundAmount > item.canRefundBalanceAmount) {
        return 2;
      } else if (item.zjRefundAmount > item.canRefundGiveAmount) {
        return 3;
      } else if (item.refundMemberCardUseNum > item.canRefundUseNum) {
        return 4;
      } else if (item.pointsRefundAmount > item.canPointsRecoveryAmount) {
        return 5;
      } else {
        return 6;
      }
    },
    // 【请求】订单退款详情
    getRefundList() {
      this.editShow = true;
      let data = {
        orderId: this.orderId,
      };
      getRefundList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.refundList = res.data;
        }
      });
    },

    // 【请求】编辑
    getRefundEdit(data) {
      getRefundEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.loading = false;

          this.editShow = false;
          this.$emit("reload");
        }else{
          this.loading = false;

        }
      });
    },

    // 【监听】表单提交
    onSubmit(formName, index) {
      this.loading = true;
      this.$refs[formName][0].validate((valid) => {
        if (valid) {
          let errType = this.goCheckRefundMoney(index);
          switch (errType) {
            case 1:
              this.loading = false;
              this.$message({
                message: "微信退款金额超出最大可退款金额",
                type: "error",
              });
              
              break;
            case 2:
              this.$message({
                message: "本金退款金额超出最大可退款金额",
                type: "error",
              });
              this.loading = false;

              break;
            case 3:
              this.$message({
                message: "赠金退款金额超出最大可退款金额",
                type: "error",
              });
              this.loading = false;

              break;
            case 4:
              this.$message({
                message: "卡券退款次数超出最大可退款次数",
                type: "error",
              });
              this.loading = false;

              break;
            case 5:
              this.$message({
                message: "收回积分超出最大可收回积分",
                type: "error",
              });
              this.loading = false;

              break;
            case 6:
              let data = {
                orderId: this.refundList[index].orderId,
                refundAmount: this.refundList[index].wxRefundAmount,
                balanceRefundAmount: this.refundList[index].bjRefundAmount,
                giveRefundAmount: this.refundList[index].zjRefundAmount,
                refundMemberCardUseNum:
                  this.refundList[index].refundMemberCardUseNum,
                pointsRecoveryAmount: this.refundList[index].pointsRefundAmount
              };
              this.getRefundEdit(data);
              break;
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: inline-flex;
  margin-left: 0.1rem;
}

.form-main {
  padding-left: 0.3rem;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.inputBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 48%;

    .el-input {
      width: 240px;
    }
  }
}
</style>